import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import TabsLayout from './tabs.mdx';
import InternalLink from '../../../components/InternalLink';
export const _frontmatter = {
  "slug": "/components/pagination/accessibility",
  "title": "Pagination - Accessibility"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = ({
  children,
  pageContext
}) => <TabsLayout pageContext={pageContext}>{children}</TabsLayout>;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "accessibility",
      "style": {
        "position": "relative"
      }
    }}>{`Accessibility`}<a parentName="h2" {...{
        "href": "#accessibility",
        "aria-label": "accessibility permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <h3 {...{
      "id": "pay-attention-to",
      "style": {
        "position": "relative"
      }
    }}>{`Pay attention to`}<a parentName="h3" {...{
        "href": "#pay-attention-to",
        "aria-label": "pay attention to permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`It is advisable to use colour combinations provided by the implementation.`}</strong>{` These combinations are ensured to comply with WCAG AA requirements. When customising colours, refer to `}<InternalLink href="/foundation/design-tokens/colour" mdxType="InternalLink">{`colour guidelines`}</InternalLink>{` to ensure accessibility.`}</li>
      <li parentName="ul">{`Each page number must have an `}<inlineCode parentName="li">{`aria-label`}</inlineCode>{` to describe it. For example, `}<inlineCode parentName="li">{`aria-label="Page 3"`}</inlineCode>{`.`}</li>
      <li parentName="ul">{`The active page number must have an `}<inlineCode parentName="li">{`aria-current="page"`}</inlineCode>{` attribute.`}</li>
      <li parentName="ul">{`The truncation element ("..." or ellipsis) does not need to have an `}<inlineCode parentName="li">{`aria-label`}</inlineCode>{`.`}</li>
      <li parentName="ul">{`Pay attention to where to move focus after a user changes a page. In some cases, you might want to move the focus to the
beginning of the search results. But in many cases, this is not advised.`}</li>
      <li parentName="ul">{`Provide a screen reader notification (`}<inlineCode parentName="li">{`aria-live`}</inlineCode>{` and `}<inlineCode parentName="li">{`aria-atomic`}</inlineCode>{`) when a user changes a page. Especially this is
important when the user changes the page by using the component's previous or next buttons. If you use the HDS React
component of the pagination, this feature is automatically provided.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      